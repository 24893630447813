import styled from 'styled-components';
import { em, size } from 'polished';

export const Media = styled.div`
    background-color: ${({ mobile }) => (mobile ? '#fff' : 'transparent')};
    padding: ${({ mobile }) => (mobile ? '1em 4%' : '0')};

    a {
        display: inline-block;
        margin-right: 1em;
    }
`;

export const CGV = styled.div`
    text-align: center;

    @media (max-width: 767px) {
        background-color: #ffffff;
    }
    
    &:first-child {
        border: 0;
    }

    > a {
        display: inline-block;
        color: var(--medium);
        font-size: ${em('12px')};
        border-left: 1px solid var(--normal);
        padding: 0 7px;
    }

    > span {
        display: inline-block;
        color: var(--medium);
        font-size: ${em('12px')};
        margin-right: 5px;
    }
`;

export const List = styled.div`
    @media (max-width: 767px) {
        display: none;

        &.__opened {
            display: block;
            padding: 1em;
            border-bottom: 1px solid #ccc;
            margin: 0;
        }
    }

    > a {
        color: var(--medium);
        font-size: ${em('13px')};
        padding: 0 0 .65em;
        display: block;

        &:hover {
            color: var(--primary);
        }
    }

    > div > a {
        color: var(--medium);
        font-size: ${em('13px')};
        padding: 0 0 .65em;
        display: block;

        &:hover {
            color: var(--primary);
        }
    }

    > span {
        color: var(--medium);
        font-size: ${em('13px')};
    }
`;

export const Links = styled.div`
    background-color: #eef2f5;
    padding: 2em 0;

    @media (max-width: 767px) {
        padding: 0;

        > .container-fluid {
            padding: 0;
            width: 100%;
        }
    }
`;

export const Title = styled.h4`
    color: var(--dark);
    font-size: ${em('17px')};
    font-weight: 500;
    padding: 0 0 1em;
    position: relative;

    @media (max-width: 767px) {
        border-bottom: 1px solid #ccc;
        padding: 1em;
        margin: 0;
    }
 
    > span {
        color: var(--dark);
        font-weight: 700;

        @media (max-width: 767px) {
            display: none;
        }
    }
`;

export const Arrow = styled.button`
    display: none;

    @media (max-width: 767px) {
        display: block;
        width: 100%;
        text-align: left;
        font-weight: 600;
    }

    > span {
        display: block;
        ${size('14px', '14px')}
        position: absolute;
        top: 50%;
        margin-top: -7px;
        right: 1.2em;
        font-size: 14px;
        color: var(--light);
        transition: all .2s;
        transform: rotateZ(0deg);
    }

    &.__clicked {
        > span {
            transform: rotateZ(180deg);
        }
    }
`;

export const Link = styled.a`
    color: var(--normal);
    font-weight: 700;
    font-size: ${em('14px')};
    display: block;
    text-align: center;
    cursor: pointer;
    
    &:hover{
        color: var(--primary);
    }
`;
