import { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useI18n } from '@src/locales';
import { Row, Col } from 'reactstrap';
import StyledContainer from '@src/components/StyledContainer';
import { generateUrl, getCategoryTitle } from '@src/utils';
import * as Styles from './links.styled';

const Socials = dynamic(() => import('./Socials'), { ssr: true });

const shop = process.env.NEXT_PUBLIC_SHOP_NAME;

const Links = ({ submenu }) => {
    const { locale } = useRouter();
    const [section, setSection] = useState('');
    const { t } = useI18n();

    function handleOpen(target) {
        setSection(section === target ? '' : target);
    }

    const allProducts = submenu.find((item) => item.id === process.env.NEXT_PUBLIC_ALL_PRODUCTS_ID);
    const ourCollection = allProducts?.sub_segments || [];

    return (
        <Styles.Links className="links">
            <StyledContainer fluid>
                <Row className="justify-content-between">
                    <Col md="2" className="mb-md-4 assistance">
                        <div>
                            <Styles.Title>
                                <span>{t('assistance')}</span>
                                <Styles.Arrow aria-label={t('CookiesValide.text')} type="button" className={`${section === 'help' ? '__clicked' : ''}`} onClick={() => handleOpen('help')}>
                                    {t('assistance')}
                                    <span className="i-chevron-down" />
                                </Styles.Arrow>
                            </Styles.Title>

                            <Styles.List className={section === 'help' ? '__opened' : ''}>
                                {
                                    shop === 'gadgets'
                                        ? <a target="_blank" rel="noopener noreferrer" href={`${process.env.NEXT_PUBLIC_FLYER}/${locale}/${t('help.link')}/${t('myCommand.link')}`}>{t('myCommand.text')}</a>
                                        : (
                                            <div className="landingPage" xs="3">
                                                <Link className="linkLandingPage" href={`/${locale}/${t('faq.link')}`}>
                                                    <a>{t('faq.text')}</a>
                                                </Link>
                                            </div>
                                        )
                                }
                                <a href={`https://www.flyer.be/${locale}/${t('contactUs.link')}`} rel="noreferrer" target="_blank">{t('contactUs.text')}</a>
                            </Styles.List>
                        </div>
                        <Styles.Media className="pt-1 d-none d-md-block">
                            <Socials />
                        </Styles.Media>
                    </Col>

                    <Col md="2" className="mb-md-4 aboutFlyer">
                        <div>
                            <Styles.Title>
                                {shop === 'gadgets' ? <span>{t('aboutFlyer.text')}</span> : <span>{t('aboutFlyerOffice.text')}</span>}
                                <Styles.Arrow aria-label={t('aboutFlyer.text')} type="button" className={`${section === 'about' ? '__clicked' : ''}`} onClick={() => handleOpen('about')}>
                                    {t('aboutFlyer.text')}
                                    <span className="i-chevron-down" />
                                </Styles.Arrow>
                            </Styles.Title>

                            <Styles.List className={section === 'about' ? '__opened' : ''}>
                                {
                                    shop === 'gadgets'
                                        ? null : (
                                            <div className="landingPage" xs="3">
                                                <Link className="linkLandingPage" href={`/${locale}/${t('whoAreWe.link')}`}>
                                                    <a>{t('whoAreWe.text')}</a>
                                                </Link>
                                            </div>
                                        )
                                }

                                {
                                    shop === 'gadgets'
                                        ? (
                                            <div className="landingPage" xs="3">
                                                <a className="linkLandingPage" href={`/${locale}/${t('satisfied.link')}`}>
                                                    {t('satisfied.text')}
                                                </a>
                                            </div>
                                        ) : (
                                            <div className="landingPage" xs="3">
                                                <a className="linkLandingPage" href={`/${locale}/${t('ourServices.link')}`}>
                                                    {t('ourServices.text')}
                                                </a>
                                            </div>
                                        )
                                }
                                {
                                    shop === 'office' && (
                                        <div className="landingPage" xs="3">
                                            <Link className="linkLandingPage" href={`/${locale}/${t('garantie.link')}`}>
                                                <a>{t('garantie.text')}</a>
                                            </Link>
                                        </div>
                                    )
                                }
                            </Styles.List>
                        </div>
                    </Col>

                    <Col md="2" className="convenient">
                        <div>
                            <Styles.Title>
                                {shop === 'gadgets' ? <span>{t('convenient')}</span> : <span>{t('clients')}</span>}
                                <Styles.Arrow aria-label={t('convenient')} type="button" className={`${section === 'a-z' ? '__clicked' : ''}`} onClick={() => handleOpen('a-z')}>
                                    {t('convenient')}
                                    <span className="i-chevron-down" />
                                </Styles.Arrow>
                            </Styles.Title>
                            <Styles.List className={section === 'a-z' ? '__opened' : ''}>
                                {
                                    shop === 'gadgets'
                                        ? <Link href={`/${locale}/${t('siteMap.link')}`}><a>{t('siteMap.text')}</a></Link>
                                        : (
                                            <div className="landingPage" xs="3">
                                                <Link className="linkLandingPage" href={`/${locale}/${t('personalDataAndCookies.link')}`}>
                                                    <a>{t('personalDataAndCookies.text')}</a>
                                                </Link>
                                            </div>
                                        )
                                }

                                {
                                    shop === 'gadgets' ? (
                                        <div className="landingPage" xs="3">
                                            <a className="linkLandingPage" href={`/${locale}/${t('orderProcess.link')}`}>
                                                {t('orderProcess.text')}
                                            </a>
                                        </div>
                                    ) : null
                                }
                            </Styles.List>
                        </div>
                    </Col>

                    <Col md="2" className="mb-md-4 collection">
                        <div>
                            <Styles.Title>
                                <span>{t('ourCollection')}</span>
                                <Styles.Arrow aria-label={t('ourCollection')} type="button" className={`${section === 'products' ? '__clicked' : ''}`} onClick={() => handleOpen('products')}>
                                    {t('ourCollection')}
                                    <span className="i-chevron-down" />
                                </Styles.Arrow>
                            </Styles.Title>

                            <Styles.List className={section === 'products' ? '__opened' : ''}>
                                {
                                    ourCollection.slice(0, 6).map((segment) => (
                                        <Link key={segment.id} href={generateUrl({ levelOne: segment, locale })} passHref prefetch={false}>
                                            <a>{getCategoryTitle(segment, locale)}</a>
                                        </Link>
                                    ))
                                }
                            </Styles.List>
                        </div>
                    </Col>

                    <Col md="2" className="follow_us">
                        <div>
                            <Styles.Title>
                                {shop === 'gadgets' ? <span>{t('followUs')}</span> : <span>{t('myCommand.text')}</span>}
                                <Styles.Arrow type="button" className={`${section === 'conditions' ? '__clicked' : ''}`} onClick={() => handleOpen('conditions')}>
                                    {t('followUs')}
                                    <span className="i-chevron-down" />
                                </Styles.Arrow>
                            </Styles.Title>

                            <Styles.List className={section === 'conditions' ? '__opened' : ''}>
                                {
                                    shop === 'gadgets'
                                        ? (
                                            <>
                                                <span>{t('stayInformedOfOurLatestOffers')}</span><br />
                                                <span>{t('SubscribeToOurNewsletter')}</span>
                                            </>
                                        ) : null
                                }
                                {
                                    shop === 'office'
                                        ? (
                                            <div className="landingPage" xs="3">
                                                <Link className="linkLandingPage" href={`/${locale}/${t('placeAnOrder.link')}`}>
                                                    <a>{t('placeAnOrder.text')}</a>
                                                </Link>
                                            </div>
                                        ) : null
                                }
                                {
                                    shop === 'office'
                                        ? (
                                            <div className="landingPage" xs="3">
                                                <Link className="linkLandingPage" href={`/${locale}/${t('deliveryOrder.link')}`}>
                                                    <a>{t('deliveryOrder.text')}</a>
                                                </Link>
                                            </div>
                                        ) : null
                                }
                                {
                                    shop === 'office'
                                        ? (
                                            <div className="landingPage" xs="3">
                                                <Link className="linkLandingPage" href={`/${locale}/${t('returnPolicy.link')}`}>
                                                    <a>{t('returnPolicy.text')}</a>
                                                </Link>
                                            </div>
                                        ) : null
                                }
                                {
                                    shop === 'office'
                                        ? (
                                            <div className="landingPage" xs="3">
                                                <Link className="linkLandingPage" href={`/${locale}/${t('conditionsOfReturn.link')}`}>
                                                    <a>{t('conditionsOfReturn.text')}</a>
                                                </Link>
                                            </div>
                                        ) : null
                                }
                                {
                                    shop === 'office'
                                        ? (
                                            <div className="landingPage" xs="3">
                                                <Link className="linkLandingPage" href={`/${locale}/${t('quotationRequest.link')}`}>
                                                    <a>{t('quotationRequest.text')}</a>
                                                </Link>
                                            </div>
                                        ) : null
                                }
                            </Styles.List>
                        </div>
                    </Col>
                </Row>

                <div>
                    <Styles.Media className="d-block d-md-none" mobile>
                        <Socials />
                    </Styles.Media>

                    <Styles.CGV className="blocklinks-footer">
                        <span>{t('pricesAreHT')}</span>
                        <a target="_blank" rel="noopener noreferrer" href={`${process.env.NEXT_PUBLIC_FLYER}/${locale}/${t('salesTermsAndConditions.link')}`}>{t('salesTermsAndConditions.text')}</a>
                        <a target="_blank" rel="noopener noreferrer" href={`${process.env.NEXT_PUBLIC_FLYER}/${locale}/${t('privacyPolicy.link')}`}>{t('privacyPolicy.text')}</a>
                        <a target="_blank" rel="noopener noreferrer" href={`${process.env.NEXT_PUBLIC_FLYER}/${locale}/${t('legalNotice.link')}`}>{t('legalNotice.text')}</a>
                        <a target="_blank" rel="noopener noreferrer" href={`${process.env.NEXT_PUBLIC_FLYER}/${locale}/${t('help.link')}/${t('CancelOrder.link')}`}>{t('CancelOrder.text')}</a>
                        <a target="_blank" rel="noopener noreferrer" href={`${process.env.NEXT_PUBLIC_FLYER}/${locale}/${t('Cookies.link')}`}>{t('Cookies.text')}</a>
                    </Styles.CGV>
                </div>
            </StyledContainer>
        </Styles.Links>
    );
};

export default Links;
